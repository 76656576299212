.appBar {
    width: 100%;
    height: 64px;
    background: #1F2D3D!important;
    position: fixed;
    z-index: 20;
}

.navBarEventAlert {
  margin-bottom: 0px;
}

.center {
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;
}

.center svg {
	padding-right: 7px;
}

.appBarLogo {
    margin: auto;
    width: fit-content;
    height: 100%;
    line-height: 60px;
    color: white;
    font-size: 17px;
    font-weight: 800;
    font-family: Cabin;
    cursor: pointer;
}

.appBarLogo:hover {
  opacity: .8;
}

.myAvatar {
      float: left;
      font-size: 20px!important;
    background-color: rgba(0,0,0,0)!important;
    border: 1.1px solid white!important;
    font-size:16px!important;
}


.navButt, .navButt p {
    color: white!important;
    cursor: pointer;
    margin: 0px;
}

.navButt {
    height: 100%;
    width: 125px;
    color: white;
    float: right;
       -webkit-transition: background .05s ease-out;
      -moz-transition: background .05s ease-out;
      -o-transition: background .05s ease-out;
      transition: background .05s ease-out;
             -webkit-transition: opacity .05s ease-out;
      -moz-transition: opacity .05s ease-out;
      -o-transition: opacity .05s ease-out;
      transition: opacity .05s ease-out;
/*      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;*/
}

.publishButt {
    background: #1FB6FF!important;
}

.publishButt:hover {
  background: #009EEB!important;
  box-shadow: inset 0px -4px 0px 0px rgba(31,61,31,.19);
}

.whiteButt:hover {
  opacity: .7;
}

/* Popover stuff */
.popoverContent {
    max-width: 200px;
    padding-top: 12px;
}

.popoverContent h4 {
  font-size: 16px;
  color: #3c4858;
}

.popoverContent p {
  font-size: 14px;
  color: #8492a6;
  margin-bottom: 5px;
}