.mainContentContainer {
    padding-top: 135px;
}

.card-1 {
    width: 300px;
    height: 200px;
    margin: auto;
}

.elementCenter {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 1px solid rgba(0,0,0,.1);
    border-radius: 8px;
    position: relative;
}

.elementCenter:hover {
    border: 1px solid rgba(0,0,0,.3);
}

.title {
    position: absolute;
    top: 20px;
    left: 20px;
    pointer-events: none;
}

.positioner {
    position: absolute;
}

.addElement {
    flex-direction: column;
    background: rgba(0,0,0,0);
    -webkit-transition: background .05s ease-out;
      -moz-transition: background .05s ease-out;
      -o-transition: background .05s ease-out;
      transition: background .05s ease-out;
    border-radius: 5px!important;
    width: 100%;
    cursor: pointer;
}

.addElement:hover {
    background: rgba(0,0,0,.1);
}

.addElement:focus {
    outline: none;
}

/*Element Selector JS*/

.elementSelectorBack {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    background: rgba(0,0,0,.05);
    display: flex;
    align-items: center;
    justify-content: center;
}

.elementSelector {
    width: 715px;
    height: 500px;
    z-index: 100;
    overflow: hidden;
    background: rgba(244,244,244,1);
    border-radius: 26px;
    box-shadow: 20px 20px 70px 0 rgba(0,0,0,0.50);
}

.hideme{
    display: none;
}

.closer {
    position: absolute;
    width: 100%;
    height: 100%;
}

.ElementOption {
    width: 100px;
    height: 100px;
    border: 2px solid rgba(255,255,255,1);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
}

.elOption {
    opacity: .8;
    width: 100px;
    text-align: center;
    cursor: pointer;

        -webkit-transition: opacity .05s ease-out;
      -moz-transition: opacity .05s ease-out;
      -o-transition: opacity .05s ease-out;
      transition: opacity .05s ease-out;

          -webkit-transition: margin-top .05s ease-out;
      -moz-transition: margin-top .05s ease-out;
      -o-transition: margin-top .05s ease-out;
      transition: margin-top .05s ease-out;
}

.elOption p {
    color: white;
    opacity: .8;
    font-size: 13px;
    width: 100%;
    margin-top: -23px;
    opacity: .8;
}

.elOption:hover {
    opacity: 1;
    margin-top: -3px;
}

.elementChoices {
    padding-top: 30px;
}

.colorBody {
    display: flex;
    padding: 70px 35px;
    padding-bottom: 0px;
}

.mainContent {
    min-height: 100vh;
    background: var(--background);
}

.colorElement {
    width: 110px;
    text-align: center;
    font-size: 13px;
    margin-right: 35px;
}


.addColorInside {
    height: 36px;
}

.addColor {
    border-radius: 1px;
    padding: 5px;
}

.addColor {
    background: rgba(0,0,0,0);
    -webkit-transition: background .05s ease-out;
      -moz-transition: background .05s ease-out;
      -o-transition: background .05s ease-out;
      transition: background .05s ease-out;
    cursor: pointer;
}

.addColor:hover {
    background: rgba(0,0,0,.1);
}

.positioner h5 {
    float: left;
}

.positioner span {
    margin-left: 15px;
    cursor: pointer;
}

.positioner span:hover {
    background: rgba(0,0,0,.1)
}

.positioner .dropdown {
    float: left;
    margin-left: 15px;
}

.titleBox h1, .titleBox p {
    display: inline-flex;
}

.titleBox p {
    margin-left: 15px;
}

.positioner h5{
  cursor: pointer;
}

.colorContainer{
    position: relative;
}

.colorSwatch, .addColor {
    box-shadow: 0 0 0 1px rgba(0,0,0,.1);
}

.colorSwatch:hover, .addColor:hover {
    box-shadow: 0 0 0 1px rgba(0,0,0,.3);
}

.addColorInside .elementCenter {
    border: none;
}

.fontElement {
    width: 110px;
    text-align: center;
    font-size: 13px;
    margin-right: 35px;
    color: #3c4858!important;
}

.dropdown-font-family {
    color: #3c4858!important;
}

#font-picker {
    width: 110px;
    margin-bottom: 5px;
    box-shadow: 0 0 0 1px rgba(0,0,0,.1);
    border-radius: 1px;
}

#font-picker:hover {
    box-shadow: 0 0 0 1px rgba(0,0,0,.3)
}

#font-picker button {
    height: 46px;
    background: white;
    transition: all 0.2s;
}

ul.font-list {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    transition: .1s!important;
        margin-top: 4px!important;
}

.font-list-item {
    height: 32px!important;
}

.font-button {
    height: 32px!important;
}

.font-button:hover {
    background: #e6f7ff!important;;
}

.componentContainer {
    padding: 20px 20px 10px 20px;
}

.listElements {
    padding: 20px 45px 0px 30px;
    height: 500px;
    overflow: scroll;
}

.positionSubtitle .mySubtitle {
    text-align: center;
    font-size: 12px;
}

.elementSelectOption .componentContainer {
    padding: 50px 10px;
}

.elementSelectOption {
    margin-bottom: 20px;
}

.elementCenter .helperText {
    opacity: 0;
}

.elementCenter:hover .helperText {
    opacity: .4;
}

.helperText {
    position: absolute;
    top: 10px;
}

.ant-btn.addElementButton i {
    vertical-align: 1px;
}

.clickyBoi {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    flex-wrap: wrap;
}

.elementCenter .clickyBoi {
    padding: 50px 0px!important;
}

.navbar-1 {
    width: 100%;
}

.ant-btn.addElementButton {
    position: absolute;
    top: 20px;
    right: 70px;
}

.htmlButton {
    position: absolute;
    top: 20px;
    right: 20px;
}

.htmlButton i {
    vertical-align: 1px;
}

i.anticon.anticon-plus {
    vertical-align: 1px;
}



.myListGroup {
    height: 100%;
    border-right: 1px solid rgba(0,0,0,.125);
}

.myListGroup .list-group-item {
    border-right: 0px;
    border-radius: 0px;
}



/*. Putitng all of the css for the bootstrap components here (this should be stored eventually.....) */

.list-group-item.active {
    z-index: 0;
}

.componentContainermodal .modal {
    display: block;
    z-index: 0;
    position: relative;
}

.sliderContainer .colorPopover {
    bottom: 62px;
}