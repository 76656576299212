@import url(https://fonts.googleapis.com/css?family=Cabin);
@import url(https://fonts.googleapis.com/css?family=Quicksand:400,600,700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  background-image: #ffffff;
  min-height: 100vh;
}

/* Doing stuff for the navbar */

.myWidth {
    max-width: 1280px;
    height: 100%;
    margin: auto;
    padding: 0px 20px;
}

.myAvatar {
    margin-top: 10px!important;
    cursor: pointer;
}

.myAvatar svg {
    margin-bottom: 6px;
}

.myAvatar:hover {
    opacity: .8!important;
}

/*. PUTTING THE AUTH STUFF HERE */

.ant-modal.authModal {
    font-family: Cabin;
}

.ant-modal.authModal .ant-modal-header {
    border-bottom: none;
}

.ant-modal.authModal .ant-modal-body {
    padding: 75px 24px;
}

.headerContent {
    text-align: center;
}

.headerContent h4 {
    color: #3c4858;
    font-size: 20px;
    margin-bottom: 5px;
}

.headerContent p {
    color: #8492a6;
    font-size: 16px;
    margin-bottom: 30px;
}

.authModal .ant-input-lg {
    height: 50px;
}

.myButton {
    padding: 0px 20px;
    line-height: 40px;
    background: #1FB6FF;
    color: white;
    border-radius: 4px;
    box-shadow: inset 0 -3px 0 rgba(31,45,61,.15);
    cursor: pointer;

    -webkit-transition: color 0.2s linear;
    transition: color 0.2s linear;

        -webkit-transition: background 0.2s linear;
    transition: background 0.2s linear;

            -webkit-transition: box-shadow 0.2s linear;
    transition: box-shadow 0.2s linear;

}

.myButton:hover {
    background: #009EEB;
}

.buttonContainer {
    padding-top: 20px;
    display: flex;
}

.myButton.greenBoi {
    background: none;
    box-shadow: inset 0 0 0 1px #e0e6ed;
    color: #8492a6;
}

.myButton.greenBoi:hover {
    box-shadow: inset 0 -3px 0 rgba(31,45,61,.15);
    background: #13CE66;
    color: white;
}

.forgotPass {
    text-align: right;
    margin-bottom: 20px;
}

.authModal .myButton {
    text-align: center;
}

.myLink {
    -webkit-transition: all .15s linear;
    transition: all .15s linear;
    color: #8492a6;
    font-size: 16px;
    line-height: 1;
    cursor: pointer;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
}

.myLink:hover {
    color: #1f2d3d;
}

.smallBottomMargin.ant-row.ant-form-item {
    margin-bottom: 15px;
}

.tallBoi {
    padding: 5px;
}

button.myButton {
    width: 100%;
        outline: none;
    border: none;
}

button.myButton:focus, button.myButton:active {
    outline: none;
    border: none;
}

.customFooter {
    text-align: center;
    font-size: 16px;
}

.customFooter p {
    color: #8492a6;
}

.customFooter em {
    -webkit-transition: all .15s linear;
    transition: all .15s linear;
    color: #1FB6FF;
    font-size: 16px;
    line-height: 1;
    cursor: pointer;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
    font-style: normal;
}

.customFooter em:hover {
    color: #009EEB;
}

.ant-modal-header {
    margin-bottom: -54px;
}

.headerIcon {
    cursor: pointer;
    -webkit-transition: color 0.3s;
    transition: color 0.3s;
}

.headerIcon:hover {
  color: rgba(0, 0, 0, 0.85)!important;
  }

  .helpLinkContainer p {
    margin-bottom: 5px;
}

.helpLink {
    -webkit-transition: all .15s linear;
    transition: all .15s linear;
    color: #1FB6FF!important;
    cursor: pointer;
}

.helpLink:hover {
    color: #009EEB!important;
}

/* HOW TO SECTION */

.howToSection h5 {
    color: #3c4858;
}

.howToSection p {
    color: #8492a6;
    font-size: 16px;
}

.howToSection {
    margin-bottom: 25px;
}

.codeHolder {
    background: #273444;
    color: #8492A6;
    text-align: center;
    padding: 20px;
    border-radius: 4px;
    margin-bottom: 20px;
    cursor: pointer;
}

.helpLinkContainer p {
    color: #8492a6;
}

.passwordFooter {
    color: #8492a6;
}

.checkDiv {
    float: left;
    border-radius: 100px;
    width: 20px;
    height: 20px;
    box-shadow: inset 0 0 0 2px #e0e6ed;
    margin-right: 10px;
    background: white;
    -webkit-transition: background .2s cubic-bezier(0,.89,.44,1);
    transition: background .2s cubic-bezier(0,.89,.44,1);
}

svg.checkbox-tick {
    fill: white;
    -webkit-transition: all .5s cubic-bezier(0,.89,.44,1);
    transition: all .5s cubic-bezier(0,.89,.44,1);
    margin-bottom: 2.1px;
}

.checkDiv.activeCheck {
    background: #13ce66;
    box-shadow: none;
}

.passwordFooter p {
    margin-bottom: 4px;
}

.passwordFooter {
    margin-bottom: 40px;
}

.ant-form-item.signupOption {
    margin-bottom: 17px;
}

.ant-form-item.signupOption.ant-form-item-with-help {
    margin-bottom: -2px;
}

p.activeText {
    color: #3c4858;
}

.mainCard {
    min-height: 500px;
    background: #F9FAFC;
    border-radius: 8px;
    box-shadow: 20px 20px 70px 0 rgba(0,0,0,0.50);
    overflow: hidden;
}

.rightHalf {
    padding-bottom: 100%;
    margin-bottom: -100%!important;
    background: white;
}

.makeStyles-root-111 {
    width: 100%;
}

.sliderContainer {
    margin-top:70px;
    height: 500px;
    overflow: scroll;
}

.mainCardContainer {
    width: 400px;
    position: fixed;
}

.hideMe {
    display: none;
}

.modalCloser {
    position: absolute;
    top: 20px;
    left: 25px;
}

/* Color Input */

/* Bar Dragging */
.cardBar {
    background: #1FB6FF;
    width: 100%;
    height: 70px;
    position: absolute;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
}

.dragme {
    width: 350px;
    height: 70px;
    margin: auto;
    cursor: -webkit-grab;
    cursor: grab;
}

.inputs h5 {
    font-size: 14px;
    font-family: Cabin;
    color: #3c4858;
}

.inputs {
    border-bottom: 1px solid #d9e1e8;
    padding: 10px 25px;
    padding-top: 25px;
}

.dragger {
    width: 65px;
}

.dragged{
    cursor: -webkit-grabbing;
    cursor: grabbing;
}

/*button.MuiButtonBase-root:focus {
    outline: none;
}
*/
img.dragIcon {
    position: absolute;
    top: 5px;
    margin: auto;
    left: 50%;
    width: 120px;
    margin-left: -60px;
    pointer-events: none;
    opacity: .4;
}

.colorContainerModal {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.colorContainerModal p {
    color: #3c4858;
    font-family: Cabin;
}

.colorContainerModal .colorElement {
    margin: 0px 20px;
}



/* GET RID OF ALL THIS CODE */

.MuiIconButton-colorSecondary:hover {
    background-color: rgba(255, 255, 255, 0.2);
}

.MuiIconButton-colorSecondary {
    background-color: rgba(0, 0, 0, 0.18);
}

.navButton i {
    margin-bottom: 5px;
}

.navButton.ant-btn {
    background: #009EEB;
    color: white;
    border: 0px;
}

.navButton.ant-btn:hover {
    background: #008EC6;
    color: white;
}

.ant-input-number.myInputBox {
    width: 55px;
    height: 26px;
    margin-top: 3px;
}

.myInputBox .ant-input-number-input {
        padding: 0 8px;
        height: 25px;
}

.ant-slider {
    margin: 10px 6px 10px;
}

.myDropdown {
    margin-left: 6px;
}

.colorContainerModal {
    margin-top: 40px;
}

.colorContainerModal h5 {
    font-size: 20px;
    color: #3c4858;
}

.colorContainerModal p.colorSubtitle {
    color: #8492a6;
    margin-bottom: 35px;
}

.appBar {
    width: 100%;
    height: 64px;
    background: #1F2D3D!important;
    position: fixed;
    z-index: 20;
}

.navBarEventAlert {
  margin-bottom: 0px;
}

.center {
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;
}

.center svg {
	padding-right: 7px;
}

.appBarLogo {
    margin: auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: 100%;
    line-height: 60px;
    color: white;
    font-size: 17px;
    font-weight: 800;
    font-family: Cabin;
    cursor: pointer;
}

.appBarLogo:hover {
  opacity: .8;
}

.myAvatar {
      float: left;
      font-size: 20px!important;
    background-color: rgba(0,0,0,0)!important;
    border: 1.1px solid white!important;
    font-size:16px!important;
}


.navButt, .navButt p {
    color: white!important;
    cursor: pointer;
    margin: 0px;
}

.navButt {
    height: 100%;
    width: 125px;
    color: white;
    float: right;
       -webkit-transition: background .05s ease-out;
      transition: background .05s ease-out;
             -webkit-transition: opacity .05s ease-out;
      transition: opacity .05s ease-out;
/*      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;*/
}

.publishButt {
    background: #1FB6FF!important;
}

.publishButt:hover {
  background: #009EEB!important;
  box-shadow: inset 0px -4px 0px 0px rgba(31,61,31,.19);
}

.whiteButt:hover {
  opacity: .7;
}

/* Popover stuff */
.popoverContent {
    max-width: 200px;
    padding-top: 12px;
}

.popoverContent h4 {
  font-size: 16px;
  color: #3c4858;
}

.popoverContent p {
  font-size: 14px;
  color: #8492a6;
  margin-bottom: 5px;
}
.logo {
  float: left;
  font-size: 20px;
  color: #1FB6FF;
  font-weight: 700;
  margin-top: 20px;
}

.contained {
    max-width: 1250px;
    width: 100%;
    margin: auto;
}

.headerBack {
    height: 64px;
        top: 0px;
    position: absolute;
    background: none;
    width: 100%;
}

.myheader {
  z-index: 2;
}

.headerBox {
}

.ant-layout {
  background: white!important;
}

.headerBox h1 {
    font-weight: 700;
    font-size: 36px;
    color: #222d39;
}

.headerBox h5 {
  color: #8492a6;
    font-size: 17px;
}

.ant-layout-header {
    background: none!important;
}

.ant-menu-item, .ant-menu-item:hover, .ant-menu-item-active, .ant-menu-item-active:hover {
  background: #1F2D3D!important;
}

.holdImage {
    justify-content: center;
    display: flex!important;
}

.holdText {
  display: flex!important;
  align-items: center!important;
  height: 500px;
}

.myAlert {
  height: 30px;
}

.myAlert {
  background: #A389F4;
}

.marketNav {
  height: 78px;
  background: #EFF2F7;
  display: flex!important;
  align-items: center!important;
  justify-content: center!important;
}
.marketNavBack {
  height: 78px;
  background: #EFF2F7;
}

.miniButton {
    padding: 0px 20px;
        margin: 0px 20px;
    line-height: 35px;
    color: white;
    border-radius: 4px;
    cursor: pointer;

    background: rgba(0,0,0,0);
    box-shadow: inset 0 0 0 1px #e0e6ed;
    color: #3c4858;

    -webkit-transition: color 0.2s linear;
    transition: color 0.2s linear;

        -webkit-transition: background 0.2s linear;
    transition: background 0.2s linear;

            -webkit-transition: box-shadow 0.2s linear;
    transition: box-shadow 0.2s linear;

}

.miniButton:hover {
  background: #85D7FF;

  box-shadow: inset 0 -3px 0 rgba(31,45,61,.15);
}

.miniButton.active {
  background: #85D7FF;
  box-shadow: inset 0 -3px 0 rgba(31,45,61,.15);
}

.buttonContainer2 {
    display: flex;
}

.market {
  padding: 30px 50px;
}

.themeContainer {
    width: 100%;
}

.themeContainer img {
    width: 100%;
}

.themeCaption {
    position: absolute;
    bottom: 0;
}

.marketBack {
  background: #F9FAFC;

}

.themeContainer {
    box-shadow: 0 1px 2px 0 rgba(31,45,61,.15);
    border: 7px solid white;
}

.themeContainer:hover .themeCaption {
}

.ant-layout {
  font-family: Cabin!important;
  min-height: 100vh!important;
}

.myFooter {
  flex: 1 1 auto;
}

.contained.headerB{
  flex: 0 1!important;
}

.myFooter {
  background: #F9FAFC;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 300px;
    color: black!important;
}

.emailLink {
    display: flex!important;
    align-items: center!important;
}

.emailLink img {
  height: 60px;
  padding-right: 30px;
}

.workWith {
  text-align: left;
}

.emailLink h4 {
    font-size: 15px;
    color: #8492a6;
}

.workWith h4, .workWith p {
    color: #8492a6;
}

.curveSection {
    margin-bottom: -1px;

}

.curveSection2 {
    margin-top: -2px;
    background: #F9FAFC;
}

.howItWorks {
    padding-bottom: 100px;
    background: #1f2d3d;
}

.titleContainer {
    display: flex;
    align-items: center;
}

.symbol {
    background: rgba(80,102,144,.1);
    border-radius: 16px;
    padding: 5px 14px;
    
    color: rgba(255,255,255,.4);
    margin-bottom: 9px;
}

p.themeTitle {
    position: absolute;
    bottom: 8px;
    left: 66px;
    font-size: 21px;
    color: black;
}

.backing {
    width: 100%;
    height: 200px;
    background: #F9FAFC;
    border-radius: 2px;
    cursor: pointer;
    display: flex!important;
    align-items: center!important;
    justify-content: center;
    overflow: hidden;
        flex-wrap: wrap;
                    -webkit-transition: margin 0.05s linear;
    transition: margin 0.05s linear;
}

.mycolorContainer {
    width: 100%;
    height: 20px;
    display: flex;
    align-self: flex-start;
}

.positionButton.demo {
    align-self: normal;
    margin-top: -15px;
}

.colorEl {
    width: 100%;
}


/*THEMSES*/

.Marvel.backing {
    border-radius: 4px;
    box-shadow: inset 0 -5px 0 rgba(31,45,61,.15);
}

.Uber.backing {
    background: white;
    border-radius: 0px;
}

.Uber.backing .myButton {
  font-family: Quicksand;
      font-weight: 600;
    background: black;
    box-shadow: none;
    padding: 14px 25px;
    border-radius: 0px;
    line-height: 28px;
    font-size: 18px;
    -webkit-transition: background .25s cubic-bezier(.2, .8, .4, 1);
    transition: background .25s cubic-bezier(.2, .8, .4, 1);
}

.Uber.backing .myButton:hover {
background: #333333
}


.Smooth.backing {
    background-image: -webkit-gradient(linear,left top, left bottom,color-stop(0, #f9fbfd),to(#fff));
    background-image: linear-gradient(to bottom,#f9fbfd 0,#fff 100%);
    border-radius: 24px;
}

.Smooth.backing .myButton {
  font-family: Quicksand;
      font-weight: 600;
    background: #335eea;
    box-shadow: none;
    padding: .8125rem 1.25rem;
    font-size: 1.0625rem;
    line-height: 1.6;
    border-radius: .375rem;
-webkit-transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.Smooth.backing .myButton:hover {
    background:  #1746e0 }

.backing:hover {
    margin-top: -4px;
}

.buildYourOwn {
    max-width: 600px;
    text-align: center;
    margin: auto;
    display: flex;
    flex-direction: column;
    padding-top: 50px;
}

.buildYourOwn .symbol, .buildYourOwn .myButton.greenBoi {
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      margin-left: auto;
    margin-right: auto;
}

.buildYourOwn p {
  color: #8492a6;
      line-height: 24px;
}

body {
  overflow-y: scroll!important;
  width:100%!important;
}

.ant-modal-mask {
  background-color: rgba(22,28,45,.8)!important;
  }

  li.ant-menu-item.myMenuItem {
    background: none!important;
    border-bottom: none!important;
}

.myheader .ant-menu-horizontal {
  border-bottom: none;
  margin-top: 20px;
}
.mainContentContainer {
    padding-top: 135px;
}

.card-1 {
    width: 300px;
    height: 200px;
    margin: auto;
}

.elementCenter {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 1px solid rgba(0,0,0,.1);
    border-radius: 8px;
    position: relative;
}

.elementCenter:hover {
    border: 1px solid rgba(0,0,0,.3);
}

.title {
    position: absolute;
    top: 20px;
    left: 20px;
    pointer-events: none;
}

.positioner {
    position: absolute;
}

.addElement {
    flex-direction: column;
    background: rgba(0,0,0,0);
    -webkit-transition: background .05s ease-out;
      transition: background .05s ease-out;
    border-radius: 5px!important;
    width: 100%;
    cursor: pointer;
}

.addElement:hover {
    background: rgba(0,0,0,.1);
}

.addElement:focus {
    outline: none;
}

/*Element Selector JS*/

.elementSelectorBack {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    background: rgba(0,0,0,.05);
    display: flex;
    align-items: center;
    justify-content: center;
}

.elementSelector {
    width: 715px;
    height: 500px;
    z-index: 100;
    overflow: hidden;
    background: rgba(244,244,244,1);
    border-radius: 26px;
    box-shadow: 20px 20px 70px 0 rgba(0,0,0,0.50);
}

.hideme{
    display: none;
}

.closer {
    position: absolute;
    width: 100%;
    height: 100%;
}

.ElementOption {
    width: 100px;
    height: 100px;
    border: 2px solid rgba(255,255,255,1);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
}

.elOption {
    opacity: .8;
    width: 100px;
    text-align: center;
    cursor: pointer;

        -webkit-transition: opacity .05s ease-out;
      transition: opacity .05s ease-out;

          -webkit-transition: margin-top .05s ease-out;
      transition: margin-top .05s ease-out;
}

.elOption p {
    color: white;
    opacity: .8;
    font-size: 13px;
    width: 100%;
    margin-top: -23px;
    opacity: .8;
}

.elOption:hover {
    opacity: 1;
    margin-top: -3px;
}

.elementChoices {
    padding-top: 30px;
}

.colorBody {
    display: flex;
    padding: 70px 35px;
    padding-bottom: 0px;
}

.mainContent {
    min-height: 100vh;
    background: var(--background);
}

.colorElement {
    width: 110px;
    text-align: center;
    font-size: 13px;
    margin-right: 35px;
}


.addColorInside {
    height: 36px;
}

.addColor {
    border-radius: 1px;
    padding: 5px;
}

.addColor {
    background: rgba(0,0,0,0);
    -webkit-transition: background .05s ease-out;
      transition: background .05s ease-out;
    cursor: pointer;
}

.addColor:hover {
    background: rgba(0,0,0,.1);
}

.positioner h5 {
    float: left;
}

.positioner span {
    margin-left: 15px;
    cursor: pointer;
}

.positioner span:hover {
    background: rgba(0,0,0,.1)
}

.positioner .dropdown {
    float: left;
    margin-left: 15px;
}

.titleBox h1, .titleBox p {
    display: inline-flex;
}

.titleBox p {
    margin-left: 15px;
}

.positioner h5{
  cursor: pointer;
}

.colorContainer{
    position: relative;
}

.colorSwatch, .addColor {
    box-shadow: 0 0 0 1px rgba(0,0,0,.1);
}

.colorSwatch:hover, .addColor:hover {
    box-shadow: 0 0 0 1px rgba(0,0,0,.3);
}

.addColorInside .elementCenter {
    border: none;
}

.fontElement {
    width: 110px;
    text-align: center;
    font-size: 13px;
    margin-right: 35px;
    color: #3c4858!important;
}

.dropdown-font-family {
    color: #3c4858!important;
}

#font-picker {
    width: 110px;
    margin-bottom: 5px;
    box-shadow: 0 0 0 1px rgba(0,0,0,.1);
    border-radius: 1px;
}

#font-picker:hover {
    box-shadow: 0 0 0 1px rgba(0,0,0,.3)
}

#font-picker button {
    height: 46px;
    background: white;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
}

ul.font-list {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    -webkit-transition: .1s!important;
    transition: .1s!important;
        margin-top: 4px!important;
}

.font-list-item {
    height: 32px!important;
}

.font-button {
    height: 32px!important;
}

.font-button:hover {
    background: #e6f7ff!important;;
}

.componentContainer {
    padding: 20px 20px 10px 20px;
}

.listElements {
    padding: 20px 45px 0px 30px;
    height: 500px;
    overflow: scroll;
}

.positionSubtitle .mySubtitle {
    text-align: center;
    font-size: 12px;
}

.elementSelectOption .componentContainer {
    padding: 50px 10px;
}

.elementSelectOption {
    margin-bottom: 20px;
}

.elementCenter .helperText {
    opacity: 0;
}

.elementCenter:hover .helperText {
    opacity: .4;
}

.helperText {
    position: absolute;
    top: 10px;
}

.ant-btn.addElementButton i {
    vertical-align: 1px;
}

.clickyBoi {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    flex-wrap: wrap;
}

.elementCenter .clickyBoi {
    padding: 50px 0px!important;
}

.navbar-1 {
    width: 100%;
}

.ant-btn.addElementButton {
    position: absolute;
    top: 20px;
    right: 70px;
}

.htmlButton {
    position: absolute;
    top: 20px;
    right: 20px;
}

.htmlButton i {
    vertical-align: 1px;
}

i.anticon.anticon-plus {
    vertical-align: 1px;
}



.myListGroup {
    height: 100%;
    border-right: 1px solid rgba(0,0,0,.125);
}

.myListGroup .list-group-item {
    border-right: 0px;
    border-radius: 0px;
}



/*. Putitng all of the css for the bootstrap components here (this should be stored eventually.....) */

.list-group-item.active {
    z-index: 0;
}

.componentContainermodal .modal {
    display: block;
    z-index: 0;
    position: relative;
}

.sliderContainer .colorPopover {
    bottom: 62px;
}
/* Welcome to jank.css.
 All code is generated from pythonCSSgenerator.py
 These files represent a generated version of bootstrap code that allows for live color updating*/

/* Background & Text ======================================================= */

body {
  background: var(--background);
  font-family: var(--font-family);
}

/* Text ======================================================= */

h1, h2, h3, h4, h5, h6, p {
    color: var(--background-text);
}

/* List ======================================================= */


.list-group-item.active {
    z-index: 2;
    color: var(--primary-text);
    background-color: var(--primary);
    border-color: var(--primary); }

/* Alert ======================================================= */

/* Alert primary */
.alert-primary {
  color: var(--primary);
  background-color: var(--primary-mix10);
  border-color: var(--primary-mix9); }
  .alert-primary hr {
    border-top-color: var(--primary-mix9); }
  .alert-primary .alert-link {
    color: var(--primary-dark); }

/* Alert secondary */
.alert-secondary {
  color: var(--secondary);
  background-color: var(--secondary-mix10);
  border-color: var(--secondary-mix9); }
  .alert-secondary hr {
    border-top-color: var(--secondary-mix9); }
  .alert-secondary .alert-link {
    color: var(--secondary-dark); }

/* Alert success */
.alert-success {
  color: var(--success);
  background-color: var(--success-mix10);
  border-color: var(--success-mix9); }
  .alert-success hr {
    border-top-color: var(--success-mix9); }
  .alert-success .alert-link {
    color: var(--success-dark); }

/* Alert danger */
.alert-danger {
  color: var(--danger);
  background-color: var(--danger-mix10);
  border-color: var(--danger-mix9); }
  .alert-danger hr {
    border-top-color: var(--danger-mix9); }
  .alert-danger .alert-link {
    color: var(--danger-dark); }

/* Alert warning */
.alert-warning {
  color: var(--warning);
  background-color: var(--warning-mix10);
  border-color: var(--warning-mix9); }
  .alert-warning hr {
    border-top-color: var(--warning-mix9); }
  .alert-warning .alert-link {
    color: var(--warning-dark); }

/* Alert info */
.alert-info {
  color: var(--info);
  background-color: var(--info-mix10);
  border-color: var(--info-mix9); }
  .alert-info hr {
    border-top-color: var(--info-mix9); }
  .alert-info .alert-link {
    color: var(--info-dark); }

/* Alert light */
.alert-light {
  color: var(--light);
  background-color: var(--light-mix10);
  border-color: var(--light-mix9); }
  .alert-light hr {
    border-top-color: var(--light-mix9); }
  .alert-light .alert-link {
    color: var(--light-dark); }

/* Alert dark */
.alert-dark {
  color: var(--dark);
  background-color: var(--dark-mix10);
  border-color: var(--dark-mix9); }
  .alert-dark hr {
    border-top-color: var(--dark-mix9); }
  .alert-dark .alert-link {
    color: var(--dark-dark); }

/* Alert white */
.alert-white {
  color: var(--white);
  background-color: var(--white-mix10);
  border-color: var(--white-mix9); }
  .alert-white hr {
    border-top-color: var(--white-mix9); }
  .alert-white .alert-link {
    color: var(--white-dark); }

/* Background ======================================================= */

/* Background primary */
.bg-primary {
    background-color: var(--primary)!important;}

/* Background secondary */
.bg-secondary {
    background-color: var(--secondary)!important;}

/* Background success */
.bg-success {
    background-color: var(--success)!important;}

/* Background danger */
.bg-danger {
    background-color: var(--danger)!important;}

/* Background warning */
.bg-warning {
    background-color: var(--warning)!important;}

/* Background info */
.bg-info {
    background-color: var(--info)!important;}

/* Background light */
.bg-light {
    background-color: var(--light)!important;}

/* Background dark */
.bg-dark {
    background-color: var(--dark)!important;}

/* Background white */
.bg-white {
    background-color: var(--white)!important;}

/* Badge ======================================================= */

/* Badge primary */
.badge-primary {
  color: var(--primary-text);
  background-color: var(--primary); }
  a.badge-primary:hover, a.badge-primary:focus {
    color: var(--primary-text);
    background-color: var(--primary-dark); }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(var(--primary-rgb), 0.5); }

/* Badge secondary */
.badge-secondary {
  color: var(--secondary-text);
  background-color: var(--secondary); }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: var(--secondary-text);
    background-color: var(--secondary-dark); }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(var(--secondary-rgb), 0.5); }

/* Badge success */
.badge-success {
  color: var(--success-text);
  background-color: var(--success); }
  a.badge-success:hover, a.badge-success:focus {
    color: var(--success-text);
    background-color: var(--success-dark); }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(var(--success-rgb), 0.5); }

/* Badge danger */
.badge-danger {
  color: var(--danger-text);
  background-color: var(--danger); }
  a.badge-danger:hover, a.badge-danger:focus {
    color: var(--danger-text);
    background-color: var(--danger-dark); }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(var(--danger-rgb), 0.5); }

/* Badge warning */
.badge-warning {
  color: var(--warning-text);
  background-color: var(--warning); }
  a.badge-warning:hover, a.badge-warning:focus {
    color: var(--warning-text);
    background-color: var(--warning-dark); }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(var(--warning-rgb), 0.5); }

/* Badge info */
.badge-info {
  color: var(--info-text);
  background-color: var(--info); }
  a.badge-info:hover, a.badge-info:focus {
    color: var(--info-text);
    background-color: var(--info-dark); }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(var(--info-rgb), 0.5); }

/* Badge light */
.badge-light {
  color: var(--light-text);
  background-color: var(--light); }
  a.badge-light:hover, a.badge-light:focus {
    color: var(--light-text);
    background-color: var(--light-dark); }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(var(--light-rgb), 0.5); }

/* Badge dark */
.badge-dark {
  color: var(--dark-text);
  background-color: var(--dark); }
  a.badge-dark:hover, a.badge-dark:focus {
    color: var(--dark-text);
    background-color: var(--dark-dark); }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(var(--dark-rgb), 0.5); }

/* Badge white */
.badge-white {
  color: var(--white-text);
  background-color: var(--white); }
  a.badge-white:hover, a.badge-white:focus {
    color: var(--white-text);
    background-color: var(--white-dark); }
  a.badge-white:focus, a.badge-white.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(var(--white-rgb), 0.5); }

/* Button ======================================================= */

/* Button primary */
.btn-primary {
  color: var(--primary-text);
  background-color: var(--primary);
  border-color: var(--primary); }
  .btn-primary:hover {
    color: var(--primary-text);
    background-color: var(--primary-dark);
    border-color: var(--primary-dark); }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(var(--primary-rgb), 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: var(--primary-text);
    background-color: var(--primary);
    border-color: var(--primary); }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: var(--primary-text);
    background-color: var(--primary-dark);
    border-color: var(--primary-dark); }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(var(--primary-rgb), 0.5); }

/* Button secondary */
.btn-secondary {
  color: var(--secondary-text);
  background-color: var(--secondary);
  border-color: var(--secondary); }
  .btn-secondary:hover {
    color: var(--secondary-text);
    background-color: var(--secondary-dark);
    border-color: var(--secondary-dark); }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(var(--secondary-rgb), 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: var(--secondary-text);
    background-color: var(--secondary);
    border-color: var(--secondary); }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: var(--secondary-text);
    background-color: var(--secondary-dark);
    border-color: var(--secondary-dark); }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(var(--secondary-rgb), 0.5); }

/* Button success */
.btn-success {
  color: var(--success-text);
  background-color: var(--success);
  border-color: var(--success); }
  .btn-success:hover {
    color: var(--success-text);
    background-color: var(--success-dark);
    border-color: var(--success-dark); }
  .btn-success:focus, .btn-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(var(--success-rgb), 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: var(--success-text);
    background-color: var(--success);
    border-color: var(--success); }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: var(--success-text);
    background-color: var(--success-dark);
    border-color: var(--success-dark); }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(var(--success-rgb), 0.5); }

/* Button danger */
.btn-danger {
  color: var(--danger-text);
  background-color: var(--danger);
  border-color: var(--danger); }
  .btn-danger:hover {
    color: var(--danger-text);
    background-color: var(--danger-dark);
    border-color: var(--danger-dark); }
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(var(--danger-rgb), 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: var(--danger-text);
    background-color: var(--danger);
    border-color: var(--danger); }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: var(--danger-text);
    background-color: var(--danger-dark);
    border-color: var(--danger-dark); }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(var(--danger-rgb), 0.5); }

/* Button warning */
.btn-warning {
  color: var(--warning-text);
  background-color: var(--warning);
  border-color: var(--warning); }
  .btn-warning:hover {
    color: var(--warning-text);
    background-color: var(--warning-dark);
    border-color: var(--warning-dark); }
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(var(--warning-rgb), 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: var(--warning-text);
    background-color: var(--warning);
    border-color: var(--warning); }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: var(--warning-text);
    background-color: var(--warning-dark);
    border-color: var(--warning-dark); }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(var(--warning-rgb), 0.5); }

/* Button info */
.btn-info {
  color: var(--info-text);
  background-color: var(--info);
  border-color: var(--info); }
  .btn-info:hover {
    color: var(--info-text);
    background-color: var(--info-dark);
    border-color: var(--info-dark); }
  .btn-info:focus, .btn-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(var(--info-rgb), 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: var(--info-text);
    background-color: var(--info);
    border-color: var(--info); }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: var(--info-text);
    background-color: var(--info-dark);
    border-color: var(--info-dark); }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(var(--info-rgb), 0.5); }

/* Button light */
.btn-light {
  color: var(--light-text);
  background-color: var(--light);
  border-color: var(--light); }
  .btn-light:hover {
    color: var(--light-text);
    background-color: var(--light-dark);
    border-color: var(--light-dark); }
  .btn-light:focus, .btn-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(var(--light-rgb), 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: var(--light-text);
    background-color: var(--light);
    border-color: var(--light); }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: var(--light-text);
    background-color: var(--light-dark);
    border-color: var(--light-dark); }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(var(--light-rgb), 0.5); }

/* Button dark */
.btn-dark {
  color: var(--dark-text);
  background-color: var(--dark);
  border-color: var(--dark); }
  .btn-dark:hover {
    color: var(--dark-text);
    background-color: var(--dark-dark);
    border-color: var(--dark-dark); }
  .btn-dark:focus, .btn-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(var(--dark-rgb), 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: var(--dark-text);
    background-color: var(--dark);
    border-color: var(--dark); }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: var(--dark-text);
    background-color: var(--dark-dark);
    border-color: var(--dark-dark); }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(var(--dark-rgb), 0.5); }

/* Button white */
.btn-white {
  color: var(--white-text);
  background-color: var(--white);
  border-color: var(--white); }
  .btn-white:hover {
    color: var(--white-text);
    background-color: var(--white-dark);
    border-color: var(--white-dark); }
  .btn-white:focus, .btn-white.focus {
    box-shadow: 0 0 0 0.2rem rgba(var(--white-rgb), 0.5); }
  .btn-white.disabled, .btn-white:disabled {
    color: var(--white-text);
    background-color: var(--white);
    border-color: var(--white); }
  .btn-white:not(:disabled):not(.disabled):active, .btn-white:not(:disabled):not(.disabled).active,
  .show > .btn-white.dropdown-toggle {
    color: var(--white-text);
    background-color: var(--white-dark);
    border-color: var(--white-dark); }
    .btn-white:not(:disabled):not(.disabled):active:focus, .btn-white:not(:disabled):not(.disabled).active:focus,
    .show > .btn-white.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(var(--white-rgb), 0.5); }

/* Input ======================================================= */

/* Input primary */


/* Input secondary */


/* Input success */


/* Input danger */


/* Input warning */


/* Input info */


/* Input light */


/* Input dark */


/* Input white */


/* Input ======================================================= */

/* Input primary */


/* Input secondary */


/* Input success */


/* Input danger */


/* Input warning */


/* Input info */


/* Input light */


/* Input dark */


/* Input white */


/* Text ======================================================= */

/* Text primary */
.bg-primary h1, .bg-primary h2, .bg-primary h3, .bg-primary h4, .bg-primary h5, .bg-primary h6, .bg-primary p {
    color: var(--primary-text);}

/* Text secondary */
.bg-secondary h1, .bg-secondary h2, .bg-secondary h3, .bg-secondary h4, .bg-secondary h5, .bg-secondary h6, .bg-secondary p {
    color: var(--secondary-text);}

/* Text success */
.bg-success h1, .bg-success h2, .bg-success h3, .bg-success h4, .bg-success h5, .bg-success h6, .bg-success p {
    color: var(--success-text);}

/* Text danger */
.bg-danger h1, .bg-danger h2, .bg-danger h3, .bg-danger h4, .bg-danger h5, .bg-danger h6, .bg-danger p {
    color: var(--danger-text);}

/* Text warning */
.bg-warning h1, .bg-warning h2, .bg-warning h3, .bg-warning h4, .bg-warning h5, .bg-warning h6, .bg-warning p {
    color: var(--warning-text);}

/* Text info */
.bg-info h1, .bg-info h2, .bg-info h3, .bg-info h4, .bg-info h5, .bg-info h6, .bg-info p {
    color: var(--info-text);}

/* Text light */
.bg-light h1, .bg-light h2, .bg-light h3, .bg-light h4, .bg-light h5, .bg-light h6, .bg-light p {
    color: var(--light-text);}

/* Text dark */
.bg-dark h1, .bg-dark h2, .bg-dark h3, .bg-dark h4, .bg-dark h5, .bg-dark h6, .bg-dark p {
    color: var(--dark-text);}

/* Text white */
.bg-white h1, .bg-white h2, .bg-white h3, .bg-white h4, .bg-white h5, .bg-white h6, .bg-white p {
    color: var(--white-text);}

/* Navbar Text ======================================================= */

/* Navbar Text primary */
.bg-primary 
  .navbar-brand {
  color: rgba(var(--primary-textrgb), .9);
  } .bg-primary .navbar-brand:hover, .navbar-brand:focus {
  color: rgba(var(--primary-textrgb), .9);}
.bg-primary 
  .navbar-nav .nav-link {
  color: rgba(var(--primary-textrgb), .5);
  } .bg-primary .navbar-nav .nav-link:hover, 
    .bg-primary .navbar-nav .nav-link:focus {
      color: rgba(var(--primary-textrgb), .7);}
    .bg-primary .navbar-nav .nav-link .disabled {
      color: rgba(var(--primary-textrgb), .7);}
.bg-primary 
  .navbar-nav .show > .nav-link,
  .bg-primary .navbar-nav .active > .nav-link,
  .bg-primary .navbar-nav .nav-link.show,
  .bg-primary .navbar-nav .nav-link.active {
    color: rgba(var(--primary-textrgb), .9);}
.bg-primary .navbar-toggler {
    color: rgba(var(--primary-textrgb), .5);
    border-color: rgba(var(--primary-textrgb), .1);}
.bg-primary .navbar-text {
  color: rgba(var(--primary-textrgb), .5);}
  .bg-primary .navbar-text a {
    color: rgba(var(--primary-textrgb), .9);}
  .bg-primary .navbar-text a:hover, 
  .bg-primary .navbar-text a:focus {
    color: rgba(var(--primary-textrgb), .9);}

/* Navbar Text secondary */
.bg-secondary 
  .navbar-brand {
  color: rgba(var(--secondary-textrgb), .9);
  } .bg-secondary .navbar-brand:hover, .navbar-brand:focus {
  color: rgba(var(--secondary-textrgb), .9);}
.bg-secondary 
  .navbar-nav .nav-link {
  color: rgba(var(--secondary-textrgb), .5);
  } .bg-secondary .navbar-nav .nav-link:hover, 
    .bg-secondary .navbar-nav .nav-link:focus {
      color: rgba(var(--secondary-textrgb), .7);}
    .bg-secondary .navbar-nav .nav-link .disabled {
      color: rgba(var(--secondary-textrgb), .7);}
.bg-secondary 
  .navbar-nav .show > .nav-link,
  .bg-secondary .navbar-nav .active > .nav-link,
  .bg-secondary .navbar-nav .nav-link.show,
  .bg-secondary .navbar-nav .nav-link.active {
    color: rgba(var(--secondary-textrgb), .9);}
.bg-secondary .navbar-toggler {
    color: rgba(var(--secondary-textrgb), .5);
    border-color: rgba(var(--secondary-textrgb), .1);}
.bg-secondary .navbar-text {
  color: rgba(var(--secondary-textrgb), .5);}
  .bg-secondary .navbar-text a {
    color: rgba(var(--secondary-textrgb), .9);}
  .bg-secondary .navbar-text a:hover, 
  .bg-secondary .navbar-text a:focus {
    color: rgba(var(--secondary-textrgb), .9);}

/* Navbar Text success */
.bg-success 
  .navbar-brand {
  color: rgba(var(--success-textrgb), .9);
  } .bg-success .navbar-brand:hover, .navbar-brand:focus {
  color: rgba(var(--success-textrgb), .9);}
.bg-success 
  .navbar-nav .nav-link {
  color: rgba(var(--success-textrgb), .5);
  } .bg-success .navbar-nav .nav-link:hover, 
    .bg-success .navbar-nav .nav-link:focus {
      color: rgba(var(--success-textrgb), .7);}
    .bg-success .navbar-nav .nav-link .disabled {
      color: rgba(var(--success-textrgb), .7);}
.bg-success 
  .navbar-nav .show > .nav-link,
  .bg-success .navbar-nav .active > .nav-link,
  .bg-success .navbar-nav .nav-link.show,
  .bg-success .navbar-nav .nav-link.active {
    color: rgba(var(--success-textrgb), .9);}
.bg-success .navbar-toggler {
    color: rgba(var(--success-textrgb), .5);
    border-color: rgba(var(--success-textrgb), .1);}
.bg-success .navbar-text {
  color: rgba(var(--success-textrgb), .5);}
  .bg-success .navbar-text a {
    color: rgba(var(--success-textrgb), .9);}
  .bg-success .navbar-text a:hover, 
  .bg-success .navbar-text a:focus {
    color: rgba(var(--success-textrgb), .9);}

/* Navbar Text danger */
.bg-danger 
  .navbar-brand {
  color: rgba(var(--danger-textrgb), .9);
  } .bg-danger .navbar-brand:hover, .navbar-brand:focus {
  color: rgba(var(--danger-textrgb), .9);}
.bg-danger 
  .navbar-nav .nav-link {
  color: rgba(var(--danger-textrgb), .5);
  } .bg-danger .navbar-nav .nav-link:hover, 
    .bg-danger .navbar-nav .nav-link:focus {
      color: rgba(var(--danger-textrgb), .7);}
    .bg-danger .navbar-nav .nav-link .disabled {
      color: rgba(var(--danger-textrgb), .7);}
.bg-danger 
  .navbar-nav .show > .nav-link,
  .bg-danger .navbar-nav .active > .nav-link,
  .bg-danger .navbar-nav .nav-link.show,
  .bg-danger .navbar-nav .nav-link.active {
    color: rgba(var(--danger-textrgb), .9);}
.bg-danger .navbar-toggler {
    color: rgba(var(--danger-textrgb), .5);
    border-color: rgba(var(--danger-textrgb), .1);}
.bg-danger .navbar-text {
  color: rgba(var(--danger-textrgb), .5);}
  .bg-danger .navbar-text a {
    color: rgba(var(--danger-textrgb), .9);}
  .bg-danger .navbar-text a:hover, 
  .bg-danger .navbar-text a:focus {
    color: rgba(var(--danger-textrgb), .9);}

/* Navbar Text warning */
.bg-warning 
  .navbar-brand {
  color: rgba(var(--warning-textrgb), .9);
  } .bg-warning .navbar-brand:hover, .navbar-brand:focus {
  color: rgba(var(--warning-textrgb), .9);}
.bg-warning 
  .navbar-nav .nav-link {
  color: rgba(var(--warning-textrgb), .5);
  } .bg-warning .navbar-nav .nav-link:hover, 
    .bg-warning .navbar-nav .nav-link:focus {
      color: rgba(var(--warning-textrgb), .7);}
    .bg-warning .navbar-nav .nav-link .disabled {
      color: rgba(var(--warning-textrgb), .7);}
.bg-warning 
  .navbar-nav .show > .nav-link,
  .bg-warning .navbar-nav .active > .nav-link,
  .bg-warning .navbar-nav .nav-link.show,
  .bg-warning .navbar-nav .nav-link.active {
    color: rgba(var(--warning-textrgb), .9);}
.bg-warning .navbar-toggler {
    color: rgba(var(--warning-textrgb), .5);
    border-color: rgba(var(--warning-textrgb), .1);}
.bg-warning .navbar-text {
  color: rgba(var(--warning-textrgb), .5);}
  .bg-warning .navbar-text a {
    color: rgba(var(--warning-textrgb), .9);}
  .bg-warning .navbar-text a:hover, 
  .bg-warning .navbar-text a:focus {
    color: rgba(var(--warning-textrgb), .9);}

/* Navbar Text info */
.bg-info 
  .navbar-brand {
  color: rgba(var(--info-textrgb), .9);
  } .bg-info .navbar-brand:hover, .navbar-brand:focus {
  color: rgba(var(--info-textrgb), .9);}
.bg-info 
  .navbar-nav .nav-link {
  color: rgba(var(--info-textrgb), .5);
  } .bg-info .navbar-nav .nav-link:hover, 
    .bg-info .navbar-nav .nav-link:focus {
      color: rgba(var(--info-textrgb), .7);}
    .bg-info .navbar-nav .nav-link .disabled {
      color: rgba(var(--info-textrgb), .7);}
.bg-info 
  .navbar-nav .show > .nav-link,
  .bg-info .navbar-nav .active > .nav-link,
  .bg-info .navbar-nav .nav-link.show,
  .bg-info .navbar-nav .nav-link.active {
    color: rgba(var(--info-textrgb), .9);}
.bg-info .navbar-toggler {
    color: rgba(var(--info-textrgb), .5);
    border-color: rgba(var(--info-textrgb), .1);}
.bg-info .navbar-text {
  color: rgba(var(--info-textrgb), .5);}
  .bg-info .navbar-text a {
    color: rgba(var(--info-textrgb), .9);}
  .bg-info .navbar-text a:hover, 
  .bg-info .navbar-text a:focus {
    color: rgba(var(--info-textrgb), .9);}

/* Navbar Text light */
.bg-light 
  .navbar-brand {
  color: rgba(var(--light-textrgb), .9);
  } .bg-light .navbar-brand:hover, .navbar-brand:focus {
  color: rgba(var(--light-textrgb), .9);}
.bg-light 
  .navbar-nav .nav-link {
  color: rgba(var(--light-textrgb), .5);
  } .bg-light .navbar-nav .nav-link:hover, 
    .bg-light .navbar-nav .nav-link:focus {
      color: rgba(var(--light-textrgb), .7);}
    .bg-light .navbar-nav .nav-link .disabled {
      color: rgba(var(--light-textrgb), .7);}
.bg-light 
  .navbar-nav .show > .nav-link,
  .bg-light .navbar-nav .active > .nav-link,
  .bg-light .navbar-nav .nav-link.show,
  .bg-light .navbar-nav .nav-link.active {
    color: rgba(var(--light-textrgb), .9);}
.bg-light .navbar-toggler {
    color: rgba(var(--light-textrgb), .5);
    border-color: rgba(var(--light-textrgb), .1);}
.bg-light .navbar-text {
  color: rgba(var(--light-textrgb), .5);}
  .bg-light .navbar-text a {
    color: rgba(var(--light-textrgb), .9);}
  .bg-light .navbar-text a:hover, 
  .bg-light .navbar-text a:focus {
    color: rgba(var(--light-textrgb), .9);}

/* Navbar Text dark */
.bg-dark 
  .navbar-brand {
  color: rgba(var(--dark-textrgb), .9);
  } .bg-dark .navbar-brand:hover, .navbar-brand:focus {
  color: rgba(var(--dark-textrgb), .9);}
.bg-dark 
  .navbar-nav .nav-link {
  color: rgba(var(--dark-textrgb), .5);
  } .bg-dark .navbar-nav .nav-link:hover, 
    .bg-dark .navbar-nav .nav-link:focus {
      color: rgba(var(--dark-textrgb), .7);}
    .bg-dark .navbar-nav .nav-link .disabled {
      color: rgba(var(--dark-textrgb), .7);}
.bg-dark 
  .navbar-nav .show > .nav-link,
  .bg-dark .navbar-nav .active > .nav-link,
  .bg-dark .navbar-nav .nav-link.show,
  .bg-dark .navbar-nav .nav-link.active {
    color: rgba(var(--dark-textrgb), .9);}
.bg-dark .navbar-toggler {
    color: rgba(var(--dark-textrgb), .5);
    border-color: rgba(var(--dark-textrgb), .1);}
.bg-dark .navbar-text {
  color: rgba(var(--dark-textrgb), .5);}
  .bg-dark .navbar-text a {
    color: rgba(var(--dark-textrgb), .9);}
  .bg-dark .navbar-text a:hover, 
  .bg-dark .navbar-text a:focus {
    color: rgba(var(--dark-textrgb), .9);}

/* Navbar Text white */
.bg-white 
  .navbar-brand {
  color: rgba(var(--white-textrgb), .9);
  } .bg-white .navbar-brand:hover, .navbar-brand:focus {
  color: rgba(var(--white-textrgb), .9);}
.bg-white 
  .navbar-nav .nav-link {
  color: rgba(var(--white-textrgb), .5);
  } .bg-white .navbar-nav .nav-link:hover, 
    .bg-white .navbar-nav .nav-link:focus {
      color: rgba(var(--white-textrgb), .7);}
    .bg-white .navbar-nav .nav-link .disabled {
      color: rgba(var(--white-textrgb), .7);}
.bg-white 
  .navbar-nav .show > .nav-link,
  .bg-white .navbar-nav .active > .nav-link,
  .bg-white .navbar-nav .nav-link.show,
  .bg-white .navbar-nav .nav-link.active {
    color: rgba(var(--white-textrgb), .9);}
.bg-white .navbar-toggler {
    color: rgba(var(--white-textrgb), .5);
    border-color: rgba(var(--white-textrgb), .1);}
.bg-white .navbar-text {
  color: rgba(var(--white-textrgb), .5);}
  .bg-white .navbar-text a {
    color: rgba(var(--white-textrgb), .9);}
  .bg-white .navbar-text a:hover, 
  .bg-white .navbar-text a:focus {
    color: rgba(var(--white-textrgb), .9);}


