/* Welcome to jank.css.
 All code is generated from pythonCSSgenerator.py
 These files represent a generated version of bootstrap code that allows for live color updating*/

/* Background & Text ======================================================= */

body {
  background: var(--background);
  font-family: var(--font-family);
}

/* Text ======================================================= */

h1, h2, h3, h4, h5, h6, p {
    color: var(--background-text);
}

/* List ======================================================= */


.list-group-item.active {
    z-index: 2;
    color: var(--primary-text);
    background-color: var(--primary);
    border-color: var(--primary); }

/* Alert ======================================================= */

/* Alert primary */
.alert-primary {
  color: var(--primary);
  background-color: var(--primary-mix10);
  border-color: var(--primary-mix9); }
  .alert-primary hr {
    border-top-color: var(--primary-mix9); }
  .alert-primary .alert-link {
    color: var(--primary-dark); }

/* Alert secondary */
.alert-secondary {
  color: var(--secondary);
  background-color: var(--secondary-mix10);
  border-color: var(--secondary-mix9); }
  .alert-secondary hr {
    border-top-color: var(--secondary-mix9); }
  .alert-secondary .alert-link {
    color: var(--secondary-dark); }

/* Alert success */
.alert-success {
  color: var(--success);
  background-color: var(--success-mix10);
  border-color: var(--success-mix9); }
  .alert-success hr {
    border-top-color: var(--success-mix9); }
  .alert-success .alert-link {
    color: var(--success-dark); }

/* Alert danger */
.alert-danger {
  color: var(--danger);
  background-color: var(--danger-mix10);
  border-color: var(--danger-mix9); }
  .alert-danger hr {
    border-top-color: var(--danger-mix9); }
  .alert-danger .alert-link {
    color: var(--danger-dark); }

/* Alert warning */
.alert-warning {
  color: var(--warning);
  background-color: var(--warning-mix10);
  border-color: var(--warning-mix9); }
  .alert-warning hr {
    border-top-color: var(--warning-mix9); }
  .alert-warning .alert-link {
    color: var(--warning-dark); }

/* Alert info */
.alert-info {
  color: var(--info);
  background-color: var(--info-mix10);
  border-color: var(--info-mix9); }
  .alert-info hr {
    border-top-color: var(--info-mix9); }
  .alert-info .alert-link {
    color: var(--info-dark); }

/* Alert light */
.alert-light {
  color: var(--light);
  background-color: var(--light-mix10);
  border-color: var(--light-mix9); }
  .alert-light hr {
    border-top-color: var(--light-mix9); }
  .alert-light .alert-link {
    color: var(--light-dark); }

/* Alert dark */
.alert-dark {
  color: var(--dark);
  background-color: var(--dark-mix10);
  border-color: var(--dark-mix9); }
  .alert-dark hr {
    border-top-color: var(--dark-mix9); }
  .alert-dark .alert-link {
    color: var(--dark-dark); }

/* Alert white */
.alert-white {
  color: var(--white);
  background-color: var(--white-mix10);
  border-color: var(--white-mix9); }
  .alert-white hr {
    border-top-color: var(--white-mix9); }
  .alert-white .alert-link {
    color: var(--white-dark); }

/* Background ======================================================= */

/* Background primary */
.bg-primary {
    background-color: var(--primary)!important;}

/* Background secondary */
.bg-secondary {
    background-color: var(--secondary)!important;}

/* Background success */
.bg-success {
    background-color: var(--success)!important;}

/* Background danger */
.bg-danger {
    background-color: var(--danger)!important;}

/* Background warning */
.bg-warning {
    background-color: var(--warning)!important;}

/* Background info */
.bg-info {
    background-color: var(--info)!important;}

/* Background light */
.bg-light {
    background-color: var(--light)!important;}

/* Background dark */
.bg-dark {
    background-color: var(--dark)!important;}

/* Background white */
.bg-white {
    background-color: var(--white)!important;}

/* Badge ======================================================= */

/* Badge primary */
.badge-primary {
  color: var(--primary-text);
  background-color: var(--primary); }
  a.badge-primary:hover, a.badge-primary:focus {
    color: var(--primary-text);
    background-color: var(--primary-dark); }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(var(--primary-rgb), 0.5); }

/* Badge secondary */
.badge-secondary {
  color: var(--secondary-text);
  background-color: var(--secondary); }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: var(--secondary-text);
    background-color: var(--secondary-dark); }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(var(--secondary-rgb), 0.5); }

/* Badge success */
.badge-success {
  color: var(--success-text);
  background-color: var(--success); }
  a.badge-success:hover, a.badge-success:focus {
    color: var(--success-text);
    background-color: var(--success-dark); }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(var(--success-rgb), 0.5); }

/* Badge danger */
.badge-danger {
  color: var(--danger-text);
  background-color: var(--danger); }
  a.badge-danger:hover, a.badge-danger:focus {
    color: var(--danger-text);
    background-color: var(--danger-dark); }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(var(--danger-rgb), 0.5); }

/* Badge warning */
.badge-warning {
  color: var(--warning-text);
  background-color: var(--warning); }
  a.badge-warning:hover, a.badge-warning:focus {
    color: var(--warning-text);
    background-color: var(--warning-dark); }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(var(--warning-rgb), 0.5); }

/* Badge info */
.badge-info {
  color: var(--info-text);
  background-color: var(--info); }
  a.badge-info:hover, a.badge-info:focus {
    color: var(--info-text);
    background-color: var(--info-dark); }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(var(--info-rgb), 0.5); }

/* Badge light */
.badge-light {
  color: var(--light-text);
  background-color: var(--light); }
  a.badge-light:hover, a.badge-light:focus {
    color: var(--light-text);
    background-color: var(--light-dark); }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(var(--light-rgb), 0.5); }

/* Badge dark */
.badge-dark {
  color: var(--dark-text);
  background-color: var(--dark); }
  a.badge-dark:hover, a.badge-dark:focus {
    color: var(--dark-text);
    background-color: var(--dark-dark); }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(var(--dark-rgb), 0.5); }

/* Badge white */
.badge-white {
  color: var(--white-text);
  background-color: var(--white); }
  a.badge-white:hover, a.badge-white:focus {
    color: var(--white-text);
    background-color: var(--white-dark); }
  a.badge-white:focus, a.badge-white.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(var(--white-rgb), 0.5); }

/* Button ======================================================= */

/* Button primary */
.btn-primary {
  color: var(--primary-text);
  background-color: var(--primary);
  border-color: var(--primary); }
  .btn-primary:hover {
    color: var(--primary-text);
    background-color: var(--primary-dark);
    border-color: var(--primary-dark); }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(var(--primary-rgb), 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: var(--primary-text);
    background-color: var(--primary);
    border-color: var(--primary); }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: var(--primary-text);
    background-color: var(--primary-dark);
    border-color: var(--primary-dark); }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(var(--primary-rgb), 0.5); }

/* Button secondary */
.btn-secondary {
  color: var(--secondary-text);
  background-color: var(--secondary);
  border-color: var(--secondary); }
  .btn-secondary:hover {
    color: var(--secondary-text);
    background-color: var(--secondary-dark);
    border-color: var(--secondary-dark); }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(var(--secondary-rgb), 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: var(--secondary-text);
    background-color: var(--secondary);
    border-color: var(--secondary); }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: var(--secondary-text);
    background-color: var(--secondary-dark);
    border-color: var(--secondary-dark); }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(var(--secondary-rgb), 0.5); }

/* Button success */
.btn-success {
  color: var(--success-text);
  background-color: var(--success);
  border-color: var(--success); }
  .btn-success:hover {
    color: var(--success-text);
    background-color: var(--success-dark);
    border-color: var(--success-dark); }
  .btn-success:focus, .btn-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(var(--success-rgb), 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: var(--success-text);
    background-color: var(--success);
    border-color: var(--success); }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: var(--success-text);
    background-color: var(--success-dark);
    border-color: var(--success-dark); }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(var(--success-rgb), 0.5); }

/* Button danger */
.btn-danger {
  color: var(--danger-text);
  background-color: var(--danger);
  border-color: var(--danger); }
  .btn-danger:hover {
    color: var(--danger-text);
    background-color: var(--danger-dark);
    border-color: var(--danger-dark); }
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(var(--danger-rgb), 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: var(--danger-text);
    background-color: var(--danger);
    border-color: var(--danger); }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: var(--danger-text);
    background-color: var(--danger-dark);
    border-color: var(--danger-dark); }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(var(--danger-rgb), 0.5); }

/* Button warning */
.btn-warning {
  color: var(--warning-text);
  background-color: var(--warning);
  border-color: var(--warning); }
  .btn-warning:hover {
    color: var(--warning-text);
    background-color: var(--warning-dark);
    border-color: var(--warning-dark); }
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(var(--warning-rgb), 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: var(--warning-text);
    background-color: var(--warning);
    border-color: var(--warning); }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: var(--warning-text);
    background-color: var(--warning-dark);
    border-color: var(--warning-dark); }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(var(--warning-rgb), 0.5); }

/* Button info */
.btn-info {
  color: var(--info-text);
  background-color: var(--info);
  border-color: var(--info); }
  .btn-info:hover {
    color: var(--info-text);
    background-color: var(--info-dark);
    border-color: var(--info-dark); }
  .btn-info:focus, .btn-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(var(--info-rgb), 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: var(--info-text);
    background-color: var(--info);
    border-color: var(--info); }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: var(--info-text);
    background-color: var(--info-dark);
    border-color: var(--info-dark); }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(var(--info-rgb), 0.5); }

/* Button light */
.btn-light {
  color: var(--light-text);
  background-color: var(--light);
  border-color: var(--light); }
  .btn-light:hover {
    color: var(--light-text);
    background-color: var(--light-dark);
    border-color: var(--light-dark); }
  .btn-light:focus, .btn-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(var(--light-rgb), 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: var(--light-text);
    background-color: var(--light);
    border-color: var(--light); }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: var(--light-text);
    background-color: var(--light-dark);
    border-color: var(--light-dark); }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(var(--light-rgb), 0.5); }

/* Button dark */
.btn-dark {
  color: var(--dark-text);
  background-color: var(--dark);
  border-color: var(--dark); }
  .btn-dark:hover {
    color: var(--dark-text);
    background-color: var(--dark-dark);
    border-color: var(--dark-dark); }
  .btn-dark:focus, .btn-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(var(--dark-rgb), 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: var(--dark-text);
    background-color: var(--dark);
    border-color: var(--dark); }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: var(--dark-text);
    background-color: var(--dark-dark);
    border-color: var(--dark-dark); }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(var(--dark-rgb), 0.5); }

/* Button white */
.btn-white {
  color: var(--white-text);
  background-color: var(--white);
  border-color: var(--white); }
  .btn-white:hover {
    color: var(--white-text);
    background-color: var(--white-dark);
    border-color: var(--white-dark); }
  .btn-white:focus, .btn-white.focus {
    box-shadow: 0 0 0 0.2rem rgba(var(--white-rgb), 0.5); }
  .btn-white.disabled, .btn-white:disabled {
    color: var(--white-text);
    background-color: var(--white);
    border-color: var(--white); }
  .btn-white:not(:disabled):not(.disabled):active, .btn-white:not(:disabled):not(.disabled).active,
  .show > .btn-white.dropdown-toggle {
    color: var(--white-text);
    background-color: var(--white-dark);
    border-color: var(--white-dark); }
    .btn-white:not(:disabled):not(.disabled):active:focus, .btn-white:not(:disabled):not(.disabled).active:focus,
    .show > .btn-white.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(var(--white-rgb), 0.5); }

/* Input ======================================================= */

/* Input primary */


/* Input secondary */


/* Input success */


/* Input danger */


/* Input warning */


/* Input info */


/* Input light */


/* Input dark */


/* Input white */


/* Input ======================================================= */

/* Input primary */


/* Input secondary */


/* Input success */


/* Input danger */


/* Input warning */


/* Input info */


/* Input light */


/* Input dark */


/* Input white */


/* Text ======================================================= */

/* Text primary */
.bg-primary h1, .bg-primary h2, .bg-primary h3, .bg-primary h4, .bg-primary h5, .bg-primary h6, .bg-primary p {
    color: var(--primary-text);}

/* Text secondary */
.bg-secondary h1, .bg-secondary h2, .bg-secondary h3, .bg-secondary h4, .bg-secondary h5, .bg-secondary h6, .bg-secondary p {
    color: var(--secondary-text);}

/* Text success */
.bg-success h1, .bg-success h2, .bg-success h3, .bg-success h4, .bg-success h5, .bg-success h6, .bg-success p {
    color: var(--success-text);}

/* Text danger */
.bg-danger h1, .bg-danger h2, .bg-danger h3, .bg-danger h4, .bg-danger h5, .bg-danger h6, .bg-danger p {
    color: var(--danger-text);}

/* Text warning */
.bg-warning h1, .bg-warning h2, .bg-warning h3, .bg-warning h4, .bg-warning h5, .bg-warning h6, .bg-warning p {
    color: var(--warning-text);}

/* Text info */
.bg-info h1, .bg-info h2, .bg-info h3, .bg-info h4, .bg-info h5, .bg-info h6, .bg-info p {
    color: var(--info-text);}

/* Text light */
.bg-light h1, .bg-light h2, .bg-light h3, .bg-light h4, .bg-light h5, .bg-light h6, .bg-light p {
    color: var(--light-text);}

/* Text dark */
.bg-dark h1, .bg-dark h2, .bg-dark h3, .bg-dark h4, .bg-dark h5, .bg-dark h6, .bg-dark p {
    color: var(--dark-text);}

/* Text white */
.bg-white h1, .bg-white h2, .bg-white h3, .bg-white h4, .bg-white h5, .bg-white h6, .bg-white p {
    color: var(--white-text);}

/* Navbar Text ======================================================= */

/* Navbar Text primary */
.bg-primary 
  .navbar-brand {
  color: rgba(var(--primary-textrgb), .9);
  } .bg-primary .navbar-brand:hover, .navbar-brand:focus {
  color: rgba(var(--primary-textrgb), .9);}
.bg-primary 
  .navbar-nav .nav-link {
  color: rgba(var(--primary-textrgb), .5);
  } .bg-primary .navbar-nav .nav-link:hover, 
    .bg-primary .navbar-nav .nav-link:focus {
      color: rgba(var(--primary-textrgb), .7);}
    .bg-primary .navbar-nav .nav-link .disabled {
      color: rgba(var(--primary-textrgb), .7);}
.bg-primary 
  .navbar-nav .show > .nav-link,
  .bg-primary .navbar-nav .active > .nav-link,
  .bg-primary .navbar-nav .nav-link.show,
  .bg-primary .navbar-nav .nav-link.active {
    color: rgba(var(--primary-textrgb), .9);}
.bg-primary .navbar-toggler {
    color: rgba(var(--primary-textrgb), .5);
    border-color: rgba(var(--primary-textrgb), .1);}
.bg-primary .navbar-text {
  color: rgba(var(--primary-textrgb), .5);}
  .bg-primary .navbar-text a {
    color: rgba(var(--primary-textrgb), .9);}
  .bg-primary .navbar-text a:hover, 
  .bg-primary .navbar-text a:focus {
    color: rgba(var(--primary-textrgb), .9);}

/* Navbar Text secondary */
.bg-secondary 
  .navbar-brand {
  color: rgba(var(--secondary-textrgb), .9);
  } .bg-secondary .navbar-brand:hover, .navbar-brand:focus {
  color: rgba(var(--secondary-textrgb), .9);}
.bg-secondary 
  .navbar-nav .nav-link {
  color: rgba(var(--secondary-textrgb), .5);
  } .bg-secondary .navbar-nav .nav-link:hover, 
    .bg-secondary .navbar-nav .nav-link:focus {
      color: rgba(var(--secondary-textrgb), .7);}
    .bg-secondary .navbar-nav .nav-link .disabled {
      color: rgba(var(--secondary-textrgb), .7);}
.bg-secondary 
  .navbar-nav .show > .nav-link,
  .bg-secondary .navbar-nav .active > .nav-link,
  .bg-secondary .navbar-nav .nav-link.show,
  .bg-secondary .navbar-nav .nav-link.active {
    color: rgba(var(--secondary-textrgb), .9);}
.bg-secondary .navbar-toggler {
    color: rgba(var(--secondary-textrgb), .5);
    border-color: rgba(var(--secondary-textrgb), .1);}
.bg-secondary .navbar-text {
  color: rgba(var(--secondary-textrgb), .5);}
  .bg-secondary .navbar-text a {
    color: rgba(var(--secondary-textrgb), .9);}
  .bg-secondary .navbar-text a:hover, 
  .bg-secondary .navbar-text a:focus {
    color: rgba(var(--secondary-textrgb), .9);}

/* Navbar Text success */
.bg-success 
  .navbar-brand {
  color: rgba(var(--success-textrgb), .9);
  } .bg-success .navbar-brand:hover, .navbar-brand:focus {
  color: rgba(var(--success-textrgb), .9);}
.bg-success 
  .navbar-nav .nav-link {
  color: rgba(var(--success-textrgb), .5);
  } .bg-success .navbar-nav .nav-link:hover, 
    .bg-success .navbar-nav .nav-link:focus {
      color: rgba(var(--success-textrgb), .7);}
    .bg-success .navbar-nav .nav-link .disabled {
      color: rgba(var(--success-textrgb), .7);}
.bg-success 
  .navbar-nav .show > .nav-link,
  .bg-success .navbar-nav .active > .nav-link,
  .bg-success .navbar-nav .nav-link.show,
  .bg-success .navbar-nav .nav-link.active {
    color: rgba(var(--success-textrgb), .9);}
.bg-success .navbar-toggler {
    color: rgba(var(--success-textrgb), .5);
    border-color: rgba(var(--success-textrgb), .1);}
.bg-success .navbar-text {
  color: rgba(var(--success-textrgb), .5);}
  .bg-success .navbar-text a {
    color: rgba(var(--success-textrgb), .9);}
  .bg-success .navbar-text a:hover, 
  .bg-success .navbar-text a:focus {
    color: rgba(var(--success-textrgb), .9);}

/* Navbar Text danger */
.bg-danger 
  .navbar-brand {
  color: rgba(var(--danger-textrgb), .9);
  } .bg-danger .navbar-brand:hover, .navbar-brand:focus {
  color: rgba(var(--danger-textrgb), .9);}
.bg-danger 
  .navbar-nav .nav-link {
  color: rgba(var(--danger-textrgb), .5);
  } .bg-danger .navbar-nav .nav-link:hover, 
    .bg-danger .navbar-nav .nav-link:focus {
      color: rgba(var(--danger-textrgb), .7);}
    .bg-danger .navbar-nav .nav-link .disabled {
      color: rgba(var(--danger-textrgb), .7);}
.bg-danger 
  .navbar-nav .show > .nav-link,
  .bg-danger .navbar-nav .active > .nav-link,
  .bg-danger .navbar-nav .nav-link.show,
  .bg-danger .navbar-nav .nav-link.active {
    color: rgba(var(--danger-textrgb), .9);}
.bg-danger .navbar-toggler {
    color: rgba(var(--danger-textrgb), .5);
    border-color: rgba(var(--danger-textrgb), .1);}
.bg-danger .navbar-text {
  color: rgba(var(--danger-textrgb), .5);}
  .bg-danger .navbar-text a {
    color: rgba(var(--danger-textrgb), .9);}
  .bg-danger .navbar-text a:hover, 
  .bg-danger .navbar-text a:focus {
    color: rgba(var(--danger-textrgb), .9);}

/* Navbar Text warning */
.bg-warning 
  .navbar-brand {
  color: rgba(var(--warning-textrgb), .9);
  } .bg-warning .navbar-brand:hover, .navbar-brand:focus {
  color: rgba(var(--warning-textrgb), .9);}
.bg-warning 
  .navbar-nav .nav-link {
  color: rgba(var(--warning-textrgb), .5);
  } .bg-warning .navbar-nav .nav-link:hover, 
    .bg-warning .navbar-nav .nav-link:focus {
      color: rgba(var(--warning-textrgb), .7);}
    .bg-warning .navbar-nav .nav-link .disabled {
      color: rgba(var(--warning-textrgb), .7);}
.bg-warning 
  .navbar-nav .show > .nav-link,
  .bg-warning .navbar-nav .active > .nav-link,
  .bg-warning .navbar-nav .nav-link.show,
  .bg-warning .navbar-nav .nav-link.active {
    color: rgba(var(--warning-textrgb), .9);}
.bg-warning .navbar-toggler {
    color: rgba(var(--warning-textrgb), .5);
    border-color: rgba(var(--warning-textrgb), .1);}
.bg-warning .navbar-text {
  color: rgba(var(--warning-textrgb), .5);}
  .bg-warning .navbar-text a {
    color: rgba(var(--warning-textrgb), .9);}
  .bg-warning .navbar-text a:hover, 
  .bg-warning .navbar-text a:focus {
    color: rgba(var(--warning-textrgb), .9);}

/* Navbar Text info */
.bg-info 
  .navbar-brand {
  color: rgba(var(--info-textrgb), .9);
  } .bg-info .navbar-brand:hover, .navbar-brand:focus {
  color: rgba(var(--info-textrgb), .9);}
.bg-info 
  .navbar-nav .nav-link {
  color: rgba(var(--info-textrgb), .5);
  } .bg-info .navbar-nav .nav-link:hover, 
    .bg-info .navbar-nav .nav-link:focus {
      color: rgba(var(--info-textrgb), .7);}
    .bg-info .navbar-nav .nav-link .disabled {
      color: rgba(var(--info-textrgb), .7);}
.bg-info 
  .navbar-nav .show > .nav-link,
  .bg-info .navbar-nav .active > .nav-link,
  .bg-info .navbar-nav .nav-link.show,
  .bg-info .navbar-nav .nav-link.active {
    color: rgba(var(--info-textrgb), .9);}
.bg-info .navbar-toggler {
    color: rgba(var(--info-textrgb), .5);
    border-color: rgba(var(--info-textrgb), .1);}
.bg-info .navbar-text {
  color: rgba(var(--info-textrgb), .5);}
  .bg-info .navbar-text a {
    color: rgba(var(--info-textrgb), .9);}
  .bg-info .navbar-text a:hover, 
  .bg-info .navbar-text a:focus {
    color: rgba(var(--info-textrgb), .9);}

/* Navbar Text light */
.bg-light 
  .navbar-brand {
  color: rgba(var(--light-textrgb), .9);
  } .bg-light .navbar-brand:hover, .navbar-brand:focus {
  color: rgba(var(--light-textrgb), .9);}
.bg-light 
  .navbar-nav .nav-link {
  color: rgba(var(--light-textrgb), .5);
  } .bg-light .navbar-nav .nav-link:hover, 
    .bg-light .navbar-nav .nav-link:focus {
      color: rgba(var(--light-textrgb), .7);}
    .bg-light .navbar-nav .nav-link .disabled {
      color: rgba(var(--light-textrgb), .7);}
.bg-light 
  .navbar-nav .show > .nav-link,
  .bg-light .navbar-nav .active > .nav-link,
  .bg-light .navbar-nav .nav-link.show,
  .bg-light .navbar-nav .nav-link.active {
    color: rgba(var(--light-textrgb), .9);}
.bg-light .navbar-toggler {
    color: rgba(var(--light-textrgb), .5);
    border-color: rgba(var(--light-textrgb), .1);}
.bg-light .navbar-text {
  color: rgba(var(--light-textrgb), .5);}
  .bg-light .navbar-text a {
    color: rgba(var(--light-textrgb), .9);}
  .bg-light .navbar-text a:hover, 
  .bg-light .navbar-text a:focus {
    color: rgba(var(--light-textrgb), .9);}

/* Navbar Text dark */
.bg-dark 
  .navbar-brand {
  color: rgba(var(--dark-textrgb), .9);
  } .bg-dark .navbar-brand:hover, .navbar-brand:focus {
  color: rgba(var(--dark-textrgb), .9);}
.bg-dark 
  .navbar-nav .nav-link {
  color: rgba(var(--dark-textrgb), .5);
  } .bg-dark .navbar-nav .nav-link:hover, 
    .bg-dark .navbar-nav .nav-link:focus {
      color: rgba(var(--dark-textrgb), .7);}
    .bg-dark .navbar-nav .nav-link .disabled {
      color: rgba(var(--dark-textrgb), .7);}
.bg-dark 
  .navbar-nav .show > .nav-link,
  .bg-dark .navbar-nav .active > .nav-link,
  .bg-dark .navbar-nav .nav-link.show,
  .bg-dark .navbar-nav .nav-link.active {
    color: rgba(var(--dark-textrgb), .9);}
.bg-dark .navbar-toggler {
    color: rgba(var(--dark-textrgb), .5);
    border-color: rgba(var(--dark-textrgb), .1);}
.bg-dark .navbar-text {
  color: rgba(var(--dark-textrgb), .5);}
  .bg-dark .navbar-text a {
    color: rgba(var(--dark-textrgb), .9);}
  .bg-dark .navbar-text a:hover, 
  .bg-dark .navbar-text a:focus {
    color: rgba(var(--dark-textrgb), .9);}

/* Navbar Text white */
.bg-white 
  .navbar-brand {
  color: rgba(var(--white-textrgb), .9);
  } .bg-white .navbar-brand:hover, .navbar-brand:focus {
  color: rgba(var(--white-textrgb), .9);}
.bg-white 
  .navbar-nav .nav-link {
  color: rgba(var(--white-textrgb), .5);
  } .bg-white .navbar-nav .nav-link:hover, 
    .bg-white .navbar-nav .nav-link:focus {
      color: rgba(var(--white-textrgb), .7);}
    .bg-white .navbar-nav .nav-link .disabled {
      color: rgba(var(--white-textrgb), .7);}
.bg-white 
  .navbar-nav .show > .nav-link,
  .bg-white .navbar-nav .active > .nav-link,
  .bg-white .navbar-nav .nav-link.show,
  .bg-white .navbar-nav .nav-link.active {
    color: rgba(var(--white-textrgb), .9);}
.bg-white .navbar-toggler {
    color: rgba(var(--white-textrgb), .5);
    border-color: rgba(var(--white-textrgb), .1);}
.bg-white .navbar-text {
  color: rgba(var(--white-textrgb), .5);}
  .bg-white .navbar-text a {
    color: rgba(var(--white-textrgb), .9);}
  .bg-white .navbar-text a:hover, 
  .bg-white .navbar-text a:focus {
    color: rgba(var(--white-textrgb), .9);}

