@import url(https://fonts.googleapis.com/css?family=Cabin);
@import url(https://fonts.googleapis.com/css?family=Quicksand:400,600,700&display=swap);

.logo {
  float: left;
  font-size: 20px;
  color: #1FB6FF;
  font-weight: 700;
  margin-top: 20px;
}

.contained {
    max-width: 1250px;
    width: 100%;
    margin: auto;
}

.headerBack {
    height: 64px;
        top: 0px;
    position: absolute;
    background: none;
    width: 100%;
}

.myheader {
  z-index: 2;
}

.headerBox {
}

.ant-layout {
  background: white!important;
}

.headerBox h1 {
    font-weight: 700;
    font-size: 36px;
    color: #222d39;
}

.headerBox h5 {
  color: #8492a6;
    font-size: 17px;
}

.ant-layout-header {
    background: none!important;
}

.ant-menu-item, .ant-menu-item:hover, .ant-menu-item-active, .ant-menu-item-active:hover {
  background: #1F2D3D!important;
}

.holdImage {
    justify-content: center;
    display: flex!important;
}

.holdText {
  display: flex!important;
  align-items: center!important;
  height: 500px;
}

.myAlert {
  height: 30px;
}

.myAlert {
  background: #A389F4;
}

.marketNav {
  height: 78px;
  background: #EFF2F7;
  display: flex!important;
  align-items: center!important;
  justify-content: center!important;
}
.marketNavBack {
  height: 78px;
  background: #EFF2F7;
}

.miniButton {
    padding: 0px 20px;
        margin: 0px 20px;
    line-height: 35px;
    color: white;
    border-radius: 4px;
    cursor: pointer;

    background: rgba(0,0,0,0);
    box-shadow: inset 0 0 0 1px #e0e6ed;
    color: #3c4858;

    -webkit-transition: color 0.2s linear;
   -moz-transition: color 0.2s linear;
    transition: color 0.2s linear;

        -webkit-transition: background 0.2s linear;
   -moz-transition: background 0.2s linear;
    transition: background 0.2s linear;

            -webkit-transition: box-shadow 0.2s linear;
   -moz-transition: box-shadow 0.2s linear;
    transition: box-shadow 0.2s linear;

}

.miniButton:hover {
  background: #85D7FF;

  box-shadow: inset 0 -3px 0 rgba(31,45,61,.15);
}

.miniButton.active {
  background: #85D7FF;
  box-shadow: inset 0 -3px 0 rgba(31,45,61,.15);
}

.buttonContainer2 {
    display: flex;
}

.market {
  padding: 30px 50px;
}

.themeContainer {
    width: 100%;
}

.themeContainer img {
    width: 100%;
}

.themeCaption {
    position: absolute;
    bottom: 0;
}

.marketBack {
  background: #F9FAFC;

}

.themeContainer {
    box-shadow: 0 1px 2px 0 rgba(31,45,61,.15);
    border: 7px solid white;
}

.themeContainer:hover .themeCaption {
}

.ant-layout {
  font-family: Cabin!important;
  min-height: 100vh!important;
}

.myFooter {
  flex: 1 1 auto;
}

.contained.headerB{
  flex: 0!important;
}

.myFooter {
  background: #F9FAFC;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 300px;
    color: black!important;
}

.emailLink {
    display: flex!important;
    align-items: center!important;
}

.emailLink img {
  height: 60px;
  padding-right: 30px;
}

.workWith {
  text-align: left;
}

.emailLink h4 {
    font-size: 15px;
    color: #8492a6;
}

.workWith h4, .workWith p {
    color: #8492a6;
}

.curveSection {
    margin-bottom: -1px;

}

.curveSection2 {
    margin-top: -2px;
    background: #F9FAFC;
}

.howItWorks {
    padding-bottom: 100px;
    background: #1f2d3d;
}

.titleContainer {
    display: flex;
    align-items: center;
}

.symbol {
    background: rgba(80,102,144,.1);
    border-radius: 16px;
    padding: 5px 14px;
    
    color: rgba(255,255,255,.4);
    margin-bottom: 9px;
}

p.themeTitle {
    position: absolute;
    bottom: 8px;
    left: 66px;
    font-size: 21px;
    color: black;
}

.backing {
    width: 100%;
    height: 200px;
    background: #F9FAFC;
    border-radius: 2px;
    cursor: pointer;
    display: flex!important;
    align-items: center!important;
    justify-content: center;
    overflow: hidden;
        flex-wrap: wrap;
                    -webkit-transition: margin 0.05s linear;
   -moz-transition: margin 0.05s linear;
    transition: margin 0.05s linear;
}

.mycolorContainer {
    width: 100%;
    height: 20px;
    display: flex;
    align-self: flex-start;
}

.positionButton.demo {
    align-self: normal;
    margin-top: -15px;
}

.colorEl {
    width: 100%;
}


/*THEMSES*/

.Marvel.backing {
    border-radius: 4px;
    box-shadow: inset 0 -5px 0 rgba(31,45,61,.15);
}

.Uber.backing {
    background: white;
    border-radius: 0px;
}

.Uber.backing .myButton {
  font-family: Quicksand;
      font-weight: 600;
    background: black;
    box-shadow: none;
    padding: 14px 25px;
    border-radius: 0px;
    line-height: 28px;
    font-size: 18px;
    transition: background .25s cubic-bezier(.2, .8, .4, 1);
}

.Uber.backing .myButton:hover {
background: #333333
}


.Smooth.backing {
    background-image: linear-gradient(to bottom,#f9fbfd 0,#fff 100%);
    border-radius: 24px;
}

.Smooth.backing .myButton {
  font-family: Quicksand;
      font-weight: 600;
    background: #335eea;
    box-shadow: none;
    padding: .8125rem 1.25rem;
    font-size: 1.0625rem;
    line-height: 1.6;
    border-radius: .375rem;
transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.Smooth.backing .myButton:hover {
    background:  #1746e0 }

.backing:hover {
    margin-top: -4px;
}

.buildYourOwn {
    max-width: 600px;
    text-align: center;
    margin: auto;
    display: flex;
    flex-direction: column;
    padding-top: 50px;
}

.buildYourOwn .symbol, .buildYourOwn .myButton.greenBoi {
      width: fit-content;
      margin-left: auto;
    margin-right: auto;
}

.buildYourOwn p {
  color: #8492a6;
      line-height: 24px;
}

body {
  overflow-y: scroll!important;
  width:100%!important;
}

.ant-modal-mask {
  background-color: rgba(22,28,45,.8)!important;
  }

  li.ant-menu-item.myMenuItem {
    background: none!important;
    border-bottom: none!important;
}

.myheader .ant-menu-horizontal {
  border-bottom: none;
  margin-top: 20px;
}