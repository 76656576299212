.mainCard {
    min-height: 500px;
    background: #F9FAFC;
    border-radius: 8px;
    box-shadow: 20px 20px 70px 0 rgba(0,0,0,0.50);
    overflow: hidden;
}

.rightHalf {
    padding-bottom: 100%;
    margin-bottom: -100%!important;
    background: white;
}

.makeStyles-root-111 {
    width: 100%;
}

.sliderContainer {
    margin-top:70px;
    height: 500px;
    overflow: scroll;
}

.mainCardContainer {
    width: 400px;
    position: fixed;
}

.hideMe {
    display: none;
}

.modalCloser {
    position: absolute;
    top: 20px;
    left: 25px;
}

/* Color Input */

/* Bar Dragging */
.cardBar {
    background: #1FB6FF;
    width: 100%;
    height: 70px;
    position: absolute;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
}

.dragme {
    width: 350px;
    height: 70px;
    margin: auto;
    cursor: grab;
}

.inputs h5 {
    font-size: 14px;
    font-family: Cabin;
    color: #3c4858;
}

.inputs {
    border-bottom: 1px solid #d9e1e8;
    padding: 10px 25px;
    padding-top: 25px;
}

.dragger {
    width: 65px;
}

.dragged{
    cursor: grabbing;
}

/*button.MuiButtonBase-root:focus {
    outline: none;
}
*/
img.dragIcon {
    position: absolute;
    top: 5px;
    margin: auto;
    left: 50%;
    width: 120px;
    margin-left: -60px;
    pointer-events: none;
    opacity: .4;
}

.colorContainerModal {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.colorContainerModal p {
    color: #3c4858;
    font-family: Cabin;
}

.colorContainerModal .colorElement {
    margin: 0px 20px;
}



/* GET RID OF ALL THIS CODE */

.MuiIconButton-colorSecondary:hover {
    background-color: rgba(255, 255, 255, 0.2);
}

.MuiIconButton-colorSecondary {
    background-color: rgba(0, 0, 0, 0.18);
}

.navButton i {
    margin-bottom: 5px;
}

.navButton.ant-btn {
    background: #009EEB;
    color: white;
    border: 0px;
}

.navButton.ant-btn:hover {
    background: #008EC6;
    color: white;
}

.ant-input-number.myInputBox {
    width: 55px;
    height: 26px;
    margin-top: 3px;
}

.myInputBox .ant-input-number-input {
        padding: 0 8px;
        height: 25px;
}

.ant-slider {
    margin: 10px 6px 10px;
}

.myDropdown {
    margin-left: 6px;
}

.colorContainerModal {
    margin-top: 40px;
}

.colorContainerModal h5 {
    font-size: 20px;
    color: #3c4858;
}

.colorContainerModal p.colorSubtitle {
    color: #8492a6;
    margin-bottom: 35px;
}
